import React, { Component } from "react"

import "./content-poetry.css"
import "../../css/global.css"
import "../../css/reset.css"
import "../../css/type.css"

class ContentPoetry extends Component {
  render() {
    return (
      <div className="content-poetry">
        <div className="content-poetry__text-1">
          <p>do you have to get a battery on the have mad menu</p>
          <p>
            set an alarm for 30 minutes
            <span>&nbsp;</span>
          </p>
          <p>
            how many months is Betten and Jones in the 3 hours of steamerian
            <span>&nbsp;</span>
          </p>
          <p>news</p>
          <p>
            what is the news<span>&nbsp;</span>
          </p>
          <p>what&#39;s the weather like for the same in my name</p>
          <p>news</p>
          <p>what&#39;s the weather like for the news</p>
          <p>
            play a game<span>&nbsp;</span>
          </p>
          <p>is it other later</p>
          <p>what is the weather like</p>
          <p>
            news<span>&nbsp;</span>
          </p>
          <p>
            news<span>&nbsp;</span>
          </p>
          <p>stop</p>
        </div>

        <div className="content-poetry__text-2">
          <p>
            what&#39;s the temperature outside <span>&nbsp;</span>
          </p>
          <p>
            what&#39;s the temperature tomorrow
            <span>&nbsp;</span>
          </p>
          <p>
            set an alarm for 1 hour
            <span>&nbsp;</span>
          </p>
          <p>
            what is the stardew Valley
            <span>&nbsp;</span>
          </p>
          <p>thank you</p>
          <p>
            tell me a joke<span>&nbsp;</span>
          </p>
          <p>
            play Monu Marily Gary <span>&nbsp;</span>
          </p>
          <p>
            news<span>&nbsp;</span>
          </p>
          <p>
            what&#39;s the weather like tomorrow
            <span>&nbsp;</span>
          </p>
          <p>
            set an alarm for 1 hour
            <span>&nbsp;</span>
          </p>
          <p>
            what has beer<span>&nbsp;</span>
          </p>
          <p>
            are you<span>&nbsp;</span>
          </p>
          <p>
            what is the sound of the morning
            <span>&nbsp;</span>
          </p>
          <p>
            what is the best wolfenstein 2 locked in a panorian battlefield 1
          </p>
          <p>
            news<span>&nbsp;</span>
          </p>
          <p>
            what&#39;s the temperature outside
            <span>&nbsp;</span>
          </p>
          <p>
            news<span>&nbsp;</span>
          </p>
          <p>
            what&#39;s the square root of 8<span>&nbsp;</span>
          </p>
          <p>&nbsp;</p>

          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>play the Earth</p>
        </div>

        <div className="content-poetry__text-3">
          <p>what sound does a directions to watch the United States</p>
          <p>
            news<span>&nbsp;</span>
          </p>
          <p>
            how many temperatures are the pool to the state of the trophy
            service<span>&nbsp;</span>
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>

          <p>
            what&#39;s the next Direction
            <span>&nbsp;</span>
          </p>
          <p>what is the day</p>
          <p>solo mountain</p>
        </div>

        <div className="content-poetry__text-4">
          <div className="content-poetry__text-4__left">
            <p>news</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>news</p>
            <p>&nbsp;</p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>
              news<span>&nbsp;</span>
            </p>
          </div>

          <div className="content-poetry__text-4__right">
            <p>what is the weather tomorrow</p>
            <p>
              play me Google stardew Valley
              <span>&nbsp;</span>
            </p>
            <p>set a reminder for a favorite universe in the US Mad Lobots</p>
            <p>
              what&#39;s the temperature outside
              <span>&nbsp;</span>
            </p>
            <p>
              play you&#39;re but you mission
              <span>&nbsp;</span>
            </p>
            <p>
              set a timer for 15 minutes
              <span>&nbsp;</span>
            </p>
            <p>
              what is the weather like
              <span>&nbsp;</span>
            </p>
            <p>
              what&#39;s the temperature outside
              <span>&nbsp;</span>
            </p>
            <p>
              what&#39;s the temperature outside
              <span>&nbsp;</span>
            </p>
            <p>
              <span>&nbsp;</span>show me a feature of some music
              <span>&nbsp;</span>
            </p>
            <p>
              why do you know what is the machine
              <span>&nbsp;</span>
            </p>
            <p>
              stop<span>&nbsp;</span>
            </p>
            <p>
              show me good menu<span>&nbsp;</span>
            </p>
            <p>
              <span>&nbsp;</span>what&#39;s the temperature outside
              <span>&nbsp;</span>
            </p>
            <p>play my character</p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>
              what&#39;s the temperature outside
              <span>&nbsp;</span>
            </p>
            <p>what&#39;s the square root of 3 3</p>
            <p>news</p>
            <p>
              what do you have a song
              <span>&nbsp;</span>
            </p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>
              what is the square root of 10
              <span>&nbsp;</span>
            </p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>what&#39;s the temperature outside</p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>what is the starless for the new colossus</p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>
              what time is it in the service
              <span>&nbsp;</span>
            </p>
            <p>
              what is the weather tomorrow
              <span>&nbsp;</span>
            </p>
            <p>
              <span>&nbsp;</span>what&#39;s the temperature outside
              <span>&nbsp;</span>
            </p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>
              news<span>&nbsp;</span>
            </p>
            <p>
              what is the white that show a song
              <span>&nbsp;</span>
            </p>
            <p>how do you make a list</p>
            <p>what&#39;s the temperature outside</p>
          </div>
        </div>
        <div className="content-poetry__text-5">
          <p>
            play Ariana Grande<span>&nbsp;</span>
          </p>
          <p>
            what is the square root of 100
            <span>&nbsp;</span>
          </p>
        </div>
      </div>
    )
  }
}
export default ContentPoetry
